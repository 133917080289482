import classNames from 'classnames';
import { ComponentPropsWithoutRef, ReactNode } from 'react';

import useNormalizedId from '@/hooks/useNormalizedId';

type CheckboxProps = {
  label?: string | ReactNode;
  value?: boolean;
  labelClassName?: string;
  disabledClassName?: string;
  className?: string;
  containerClassName?: string;
} & Omit<ComponentPropsWithoutRef<'input'>, 'value'>;

export function Checkbox({
  id,
  hideLabel,
  label,
  labelClassName,
  value,
  disabled,
  disabledClassName,
  onChange,
  className,
  containerClassName,
  ...rest
}: { hideLabel?: boolean } & CheckboxProps) {
  const normalizedId = useNormalizedId(id);

  return (
    <div
      className={classNames(
        'flex justify-start items-center',
        containerClassName
      )}
    >
      <input
        id={normalizedId}
        type="checkbox"
        className={classNames(
          'cursor-pointer',
          disabled
            ? classNames(
                ' disabled:bg-gray-200 disabled:border-gray-200 disabled:cursor-not-allowed rounded',
                disabledClassName
              )
            : '',
          className
        )}
        onChange={onChange}
        checked={value}
        disabled={disabled}
        {...rest}
      />
      {label && (
        <label
          className={classNames(
            'ml-2',
            hideLabel && 'hidden',
            labelClassName ?? ''
          )}
          htmlFor={normalizedId}
        >
          {label}
        </label>
      )}
    </div>
  );
}
