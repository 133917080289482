import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { forwardRef } from 'react';

import Input, { InputProps, InputHandle } from '@/rollbar-ui/Input';

type EmailProps = { hideLabel?: boolean } & Partial<InputProps>;

export const Email = forwardRef<InputHandle, EmailProps>(function Email(
  { ...restProps },
  ref
) {
  return (
    <Input
      ref={ref}
      type="email"
      label="Email"
      leadingIcon={EnvelopeIcon}
      autoComplete="email"
      {...restProps}
    />
  );
});
