import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

import Input, { InputProps } from '@/rollbar-ui/Input';

export function Search({
  hideLabel = true,
  ...restProps
}: { hideLabel?: boolean } & Partial<InputProps>) {
  return (
    <Input
      leadingIcon={MagnifyingGlassIcon}
      type="search"
      label="Search"
      hideLabel={hideLabel}
      {...restProps}
    />
  );
}
