import { useMemo } from 'react';

let counter = 0;

/** There are times when we need to tie together 2
 *  elements in html, the canonical example of this is
 *  attaching a label to a form input. This is done by
 *  passing the id for the input to the htmlFor prop on
 *  the label.
 *
 *  Since we are trying to be certain we are making our
 *  app accessible by default we are going to always
 *  have a label for our inputs.
 *
 *  So as an ease of use option when building the input
 *  we generate an id for the field if the caller does
 *  not explicity provide one.
 */
export default function useNormalizedId(id?: string) {
  return useMemo(() => {
    return id || `id-${counter++}`;
  }, [id]);
}
