import { UserIcon } from '@heroicons/react/24/solid';

import Input, { InputProps } from '@/rollbar-ui/Input';

export function Name({ ...restProps }: Partial<InputProps>) {
  return (
    <Input
      type="text"
      label="Name"
      autoComplete="name"
      leadingIcon={UserIcon}
      {...restProps}
    />
  );
}
