import {
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
  EyeIcon,
  EyeSlashIcon,
} from '@heroicons/react/20/solid';
import classNames, { Argument as ClassNameArgument } from 'classnames';
import {
  ComponentPropsWithoutRef,
  FocusEvent,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import useNormalizedId from '@/hooks/useNormalizedId';
import Tooltip from '@/rollbar-ui/Tooltip';

export type InputProps = {
  arrangement?: 'row' | 'stacked';
  leadingIcon?: any;
  label: string;
  labelClassName?: string;
  hideLabel?: boolean;
  topAlignLabel?: boolean;
  collapseError?: boolean;
  error?: string;
  tooltip?: ReactNode;
  uppercase?: boolean;
  autofocus?: boolean;
  hideShadow?: boolean;
  inputClassName?: string;
  renderEmptyError?: boolean;
} & ComponentPropsWithoutRef<'input'>;

export type InputHandle = {
  focus: () => void;
};

export function buildInputClassName(...additional: ClassNameArgument[]) {
  return classNames(
    'block shadow-none border border-gray-300 rounded-md w-full',
    'text-base md:text-sm text-gray-900 leading-5 font-normal tracking-wider placeholder-gray-400',
    'hover:border-blue-300 hover:placeholder-gray-500',
    'focus:outline-none focus:border-blue-400 focus:ring-1 focus:ring-blue-600',
    ...additional
  );
}

export const Field = forwardRef<InputHandle, InputProps>(function Field(
  {
    arrangement = 'stacked',
    id,
    disabled,
    type = 'text',
    leadingIcon: LeadingIcon,
    label,
    labelClassName,
    topAlignLabel,
    hideLabel,
    collapseError,
    error,
    onFocus = () => {},
    tooltip,
    uppercase,
    autofocus,
    hideShadow,
    inputClassName,
    renderEmptyError = true,
    ...inputProps
  },
  handleRef
) {
  const normalizedId = useNormalizedId(id);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (autofocus) {
      inputRef.current?.focus();
    }
  }, [autofocus, inputRef]);

  useImperativeHandle(
    handleRef,
    () => {
      return {
        focus: () => {
          inputRef.current?.focus();
        },
      };
    },
    [inputRef]
  );

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      onFocus(e);
    },
    [onFocus]
  );

  const errorProps = error
    ? {
        'aria-invalid': true,
        'aria-describedby': `${normalizedId}-error`,
      }
    : {};

  const errorInputClassName = error
    ? 'pr-10 border-red-300 placeholder-red-400 focus:ring-1 focus:ring-red-500 focus:border-red-300 focus:placeholder-red-600 ' +
      'hover:border-red-300 hover:placeholder-red-600'
    : '';
  const disabledInputClassName = disabled
    ? 'hover: cursor-not-allowed text-gray-300'
    : '';

  return (
    <div
      className={classNames({
        'flex w-full flex-col justify-start items-start':
          arrangement === 'stacked',
        'flex flex-col justify-between w-full items-start sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline':
          arrangement === 'row',
      })}
    >
      <label
        htmlFor={normalizedId}
        className={classNames(
          'block text-sm font-medium text-gray-700 leading-5',
          {
            'sr-only': hideLabel,
            'self-start mt-1': topAlignLabel,
            uppercase,
          },
          labelClassName
        )}
      >
        <div className="flex flex-row">
          {tooltip ? (
            <Tooltip label={tooltip} margin={8} position="right">
              <div className="flex flex-row items-center">
                {label}
                <div className="ml-1.5">
                  <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400" />
                </div>
              </div>
            </Tooltip>
          ) : (
            label
          )}
        </div>
      </label>
      <div className="block w-full sm:col-span-2">
        <div
          className={classNames(
            'relative flex rounded-md',
            !hideShadow ? 'shadow-sm' : '',
            !hideLabel ? 'mt-1' : ''
          )}
        >
          {LeadingIcon && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <LeadingIcon
                className={classNames(
                  'h-5 w-5 text-gray-400',
                  error && 'text-red-500'
                )}
                aria-hidden="true"
              />
            </div>
          )}
          <input
            id={normalizedId}
            ref={inputRef}
            disabled={disabled}
            type={
              type === 'password' ? (showPassword ? 'text' : 'password') : type
            }
            className={buildInputClassName(
              {
                'pl-10': Boolean(LeadingIcon),
              },
              disabledInputClassName,
              errorInputClassName,
              inputClassName
            )}
            aria-label={label}
            {...errorProps}
            {...inputProps}
            onFocus={handleFocus}
          />
          {type === 'password' && (
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
            >
              {showPassword ? (
                <EyeIcon className="h-5 w-5 text-gray-500" />
              ) : (
                <EyeSlashIcon className="h-5 w-5 text-gray-500" />
              )}
            </button>
          )}
          {error && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {(error || renderEmptyError) && (
          <p
            className={classNames(
              'mt-0.5 text-xs leading-4 font-normal tracking-wider text-red-600',
              {
                // Note we want invisible here instead of hidden
                // because we want it to take vertical space
                invisible: Boolean(!error),
                hidden: collapseError && !error,
              }
            )}
            id={`${normalizedId}-error`}
          >
            {error || '&nbsp'}
          </p>
        )}
      </div>
    </div>
  );
});
