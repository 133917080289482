import {
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import {
  ComponentPropsWithoutRef,
  FocusEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import useNormalizedId from '@/hooks/useNormalizedId';
import Button from '@/rollbar-ui/Button';
import Tooltip from '@/rollbar-ui/Tooltip';

export type MultilineProps = {
  action?: boolean;
  actionText?: string;
  arrangement?: 'row' | 'stacked';
  autofocus?: boolean;
  collapseError?: boolean;
  disableAction?: boolean;
  error?: string;
  hideLabel?: boolean;
  inputClassName?: string;
  isAutoSized?: boolean;
  label: string;
  labelClassName?: string;
  leadingIcon?: any;
  tooltip?: ReactNode;
  topAlignLabel?: boolean;
  uppercase?: boolean;
} & ComponentPropsWithoutRef<'textarea'>;

export function Multiline({
  action,
  actionText,
  arrangement = 'stacked',
  autofocus,
  collapseError,
  disableAction,
  error,
  hideLabel,
  id,
  inputClassName,
  isAutoSized = false,
  label,
  labelClassName,
  leadingIcon: LeadingIcon,
  onBlur = () => {},
  onFocus = () => {},
  onSubmit = () => {},
  tooltip,
  topAlignLabel,
  uppercase,
  ...inputProps
}: MultilineProps) {
  const normalizedId = useNormalizedId(id);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (autofocus) {
      textareaRef.current?.focus();
    }
  }, [autofocus, textareaRef]);

  // When isAutoSized is true, auto-adjust the height of the textarea when the value changes
  useEffect(() => {
    if (isAutoSized && textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [inputProps.value, isAutoSized]);

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLTextAreaElement>) => {
      onFocus(e);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLTextAreaElement>) => {
      onBlur(e);
    },
    [onBlur]
  );

  const errorProps = error
    ? {
        'aria-invalid': true,
        'aria-describedby': `${normalizedId}-error`,
      }
    : {};

  const errorInputClassName = error
    ? 'pr-10 border-red-300 placeholder-red-400 focus:ring-1 focus:ring-red-500 focus:border-red-300 focus:placeholder-red-600 ' +
      'hover:border-red-300 hover:placeholder-red-600'
    : '';

  return (
    <div
      className={classNames({
        'flex flex-col justify-start items-start': arrangement === 'stacked',
        'flex flex-col justify-between w-full items-start sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline':
          arrangement === 'row',
      })}
    >
      <label
        htmlFor={normalizedId}
        className={classNames(
          'block text-sm font-medium text-gray-700 leading-5',
          {
            'sr-only': hideLabel,
            'self-start mt-1': topAlignLabel,
            uppercase,
          },
          labelClassName
        )}
      >
        <div className="flex flex-row">
          {tooltip ? (
            <Tooltip label={tooltip} position="right">
              <div className="flex flex-row items-center">
                {label}
                <div className="ml-1.5">
                  <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400" />
                </div>
              </div>
            </Tooltip>
          ) : (
            label
          )}
        </div>
      </label>
      <div className="block w-full sm:col-span-2">
        <div className="mt-1 relative flex rounded-md shadow-sm">
          {LeadingIcon && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <LeadingIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          )}
          <textarea
            id={normalizedId}
            ref={textareaRef}
            className={classNames(
              'block shadow-none border border-gray-300 rounded-md w-full',
              'text-sm text-gray-900 leading-5 font-normal tracking-wider placeholder-gray-400',
              'hover:border-blue-300 hover:placeholder-gray-500 hover:placeholder-gray-500',
              'focus:outline-none focus:border-blue-400 focus:ring-1 focus:ring-blue-600',
              {
                'pl-10': Boolean(LeadingIcon),
                'overflow-hidden': isAutoSized,
              },
              errorInputClassName,
              inputClassName || ''
            )}
            {...errorProps}
            {...inputProps}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {error && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
          {action && (
            <div className="absolute inset-y-0 right-0 pr-3 pb-3 flex items-end">
              <Button size="xs" disabled={disableAction} onClick={onSubmit}>
                {actionText}
              </Button>
            </div>
          )}
        </div>
        <p
          className={classNames(
            'mt-0.5 text-xs leading-4 font-normal tracking-wider text-red-600',
            {
              // Note we want invisible here instead of hidden
              // because we want it to take vertical space
              invisible: Boolean(!error),
              hidden: collapseError && !error,
            }
          )}
          id={`${normalizedId}-error`}
        >
          {error || '&nbsp'}
        </p>
      </div>
    </div>
  );
}
